import React, { useState } from "react";
import CreateQuestion from "./../components/CreateQuestion";
import ListQuestionsAnswers from "../components/ListQuestionsAnswers";
import ShowMetrics from "../components/ShowMetrics";
import ShowOrganizationsList from "../components/ShowOrganizationsList";
import {db} from "../config/firebase-config";
import { getAuth } from "firebase/auth";
import { addDoc, collection, query, where, getDocs } from "firebase/firestore";
import mixpanel from "mixpanel-browser";

const Home = props => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [sessionCode, setSessionCode] = useState([]);
  const [organizationsList, setOrganizationsList] = useState([]);
  const [spanishLanguage, setSpanishLanguage] = useState(false); 

  //const [updateQuestions, setUpdateQuestions] = useState(false);
  //const [updateAnswers, setUpdateAnswers] = useState(false);

  // Alert the user to sign up so they don't lose their question. Bug is that it appears when navigation to sign up or reloading
  // window.addEventListener("beforeunload", function (e) {
  //   if (questions.length > 0) {
  //     // Browner no longer let you customize the message
  //     e.returnValue = "";           
  //   }
  // });


  // Refresh list of questions
  const onQuestionCreated = (sessionCode, newQuestion) => {
    console.log("On question created (update UI)");

    // Fix because user can refresh the website and continue asking
    if (!props.isSignedIn && questions.length === 1) {
      window.alert("You have reached the maximum number of questions. Please sign up to ask more questions.")
      // Once they press OK, they will be redirected to the sign up page
      window.location.href = "/signup"
      
    }
    else {
      console.log("Set questions")
      // Update the list of Q&As
      if (newQuestion){
        // Create new array so it re-renders
        let allQuestions = [...questions]
        // let allQuestions = questions;
        allQuestions.push(newQuestion);
        setQuestions(allQuestions);
        console.log("Questions in Home:", allQuestions)

        //setUpdateQuestions(!updateQuestions)
      }
      setSessionCode(sessionCode)
      
      const chatbot = document.querySelector("#chatbot-messages");
      chatbot.scrollTo({
        top: chatbot.scrollHeight,
        behavior: "smooth",
      })
    }
  };

  // Refresh list of answers
  const onAnswerCreated = (sessionCode, newAnswer) => {
    console.log("On answer created (update UI)");
    if (newAnswer) {
      let allAnswers  = [...answers]
      //let allAnswers = answers;
      allAnswers.push(newAnswer);
      setAnswers(allAnswers)
      console.log("Answers in Home:", allAnswers)

      //setUpdateAnswers(!updateAnswers)
    }
    setSessionCode(sessionCode)

    const chatbot = document.querySelector("#chatbot-messages");
    chatbot.scrollTo({
      top: chatbot.scrollHeight,
      behavior: "smooth",
    })
  }

  // Refresh list of organizations
  const onOrganizationsListCreated = (organizationsList) => {
    console.log("On organizations list created (update UI)");
    setOrganizationsList(organizationsList)
    console.log("Organizations List in Home:", organizationsList)
  }
      
  //     fetch(`/api/session/${sessionCode}`)
  //     .then(response => {
  //       if (response.ok) {
  //         response.json().then(data => {
  //           console.log("OnQuestionCreated:" , data.questions)
  //           setQuestions(data.questions);
  //           setAnswers(data.answers)
  //           setSessionCode(sessionCode)
  //           const chatbot = document.querySelector("#chatbot-messages");
  //           chatbot.scrollTo({
  //             top: chatbot.scrollHeight,
  //             behavior: "smooth",
  //           })
  //         });
  //       }
  //     });
  //   }
  // };

  const MatchButtonPressed = ()=>{
      if (props.isSignedIn){
        // Sent alert that user will receive a message
        window.alert("You will receive an email shortly with a lawyer that matches your needs")
        // Signal us that we need to match this user with a lawyer
        /*fetch(`/api/matchLawyer/${sessionCode}`)
        .then(response => {
          if (response.ok) {
            response.json().then(data => {
              console.log("matchLawyer info: ", data)
            });
          }
        });*/
        addDoc(collection(db, "Lawyer Matching"), {
          session_id: sessionCode,
          date: Date(Date.now).toString(),
        }).then((docRef) => {
          console.log("Client asked to match with a lawyer: ")
        });
      } else {
        // Sent alert that user needs to sign in first
        window.alert("You need to sign in first")
      }
    }

    const EnglishButtonPressed = ()=>{
      console.log("English button pressed")
      // Change is-active between English and Spanish tabs
      document.getElementById("EnglishButton").className = "is-active"
      document.getElementById("SpanishButton").className = ""
      setSpanishLanguage(false)
      // Track question event with Mixpanel
      mixpanel.track("User switch Language to English")
    }

    const SpanishButtonPressed = ()=>{
      console.log("Spanish button pressed")
      // Change is-active between English and Spanish tabs
      document.getElementById("SpanishButton").className = "is-active"
      document.getElementById("EnglishButton").className = ""
      setSpanishLanguage(true)
      // Track question event with Mixpanel
      mixpanel.track("User switch Language to Spanish");
    }


  return (
    <>
      {!props.isSignedIn && (
        <section className="Introduction section is-medium has-background-info-light" id = "Welcome">
          <div className="tabs is-toggle is-small ">
            <ul>
              <li className="is-active" onClick= {EnglishButtonPressed} id = "EnglishButton">
                <a>
                  <span>English</span>
                </a>
              </li>
              <li onClick= {SpanishButtonPressed} id = "SpanishButton">
                <a>
                  <span>Español</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="columns is-vcentered">
          {!spanishLanguage && (
            <div className="column is-offset-1">
              <p className="title">Welcome to 
                <span style = {{color: "dodgerblue"}}> A</span>
                <span>D</span>
                <span style = {{color: "dodgerblue"}}>I</span>
                BILIS
              </p>
              <p className="subtitle is-size-4">
                <span style = {{color: "dodgerblue"}}>AI </span>
                to help individuals solve their legal doubts and better match
                with lawyers.
              </p>
              <p className="subtitle is-size-4">No fees, no hassle, just help.</p>
              
              <button id="learnButton" className="button is-black"
              onClick = {(e)=>{
                e.preventDefault();
                window.scrollTo({
                top: document.querySelector("#Chatbot").offsetTop,
                behavior: "smooth",
            });}}
            >Try it now!</button>
            </div>
            )}
            {spanishLanguage && (
            <div className="column is-offset-1">
              <p className="title">Bienvenido a 
                <span style = {{color: "dodgerblue"}}> A</span>
                <span>D</span>
                <span style = {{color: "dodgerblue"}}>I</span>
                BILIS
              </p>
              <p className="subtitle is-size-4">
                <span style = {{color: "dodgerblue"}}>Inteligencia Artificial </span>
                para ayudarte a resolver tus dudas legales y conectarte con el abogado ideal.
              </p>
              <p className="subtitle is-size-4">Gratis, fácil e immediato.</p>
              
              <button id="learnButton" className="button is-black"
              onClick = {(e)=>{
                e.preventDefault();
                window.scrollTo({
                top: document.querySelector("#Chatbot").offsetTop,
                behavior: "smooth",
            });}}
            >Pruébalo ya!</button>
            </div>
            )}
          
            <div className="column">
              <figure className="image">
                <img width="1024" src="/introimage.png" alt="LogoIntro"/>
              </figure>
            </div>
          </div>
        </section>
      )}

      <section className = "section is-medium" id = "Chatbot">
        {!spanishLanguage && (
        <p className="title has-text-centered">Ask any legal question!</p>
        )}
        {spanishLanguage && (
        <p className="title has-text-centered">Haz cualquier pregunta legal!</p>
        )}
        <div className="columns">
          <div className="column">
            <div className="box has-background-info-light" style = {{height: "550px"}}>
              <ListQuestionsAnswers
                sessionCode={sessionCode}
                questions={questions}
                answers = {answers}
              />
              <CreateQuestion
              sessionCode={sessionCode}
              onQuestionCreated={onQuestionCreated}
              onAnswerCreated={onAnswerCreated}
              onOrganizationsListCreated={onOrganizationsListCreated}
              spanishLanguage = {spanishLanguage}
              />
            </div>
          </div>
          {!spanishLanguage && (
          <div className="column">
            {organizationsList.length <1 && (
            <div>
              <p
                className="paragraph has-text-weight-bold py-3 is-size-4 has-text-centered"
              >
                Tips to get better answers!
              </p>
              <ul>
                <li className="px-5 is-size-5">
                  &#x2022; No need to give any personal information!
                </li>
                <li className="px-5 is-size-5">
                  &#x2022; The more context, the better results!
                </li>
              </ul>
              <br />
              <p className="paragraph is-size-5">Example 1:</p>
              <p className="paragraph has-text-justified is-size-5">
                "I have contacted my landlord two weeks ago because my shower broke
                but I haven't got a response. What should I do?"
              </p>
              <br />
              <p className="paragraph is-size-5">Example 2:</p>
              <p
                className="paragraph has-text-justified is-size-5"
              >
                "How can I file for a divorce?"
              </p>
            </div>
            )}

            {organizationsList.length >0 && (
              <ShowOrganizationsList
              list = {organizationsList}
              spanishLanguage = {spanishLanguage}
              />
            )}

            <br />
            <ShowMetrics
            answers = {answers}
            spanishLanguage = {spanishLanguage}
            />
          
            <div className="column has-text-centered">
              <button id="matchButton" className="button is-black is-medium" onClick = {MatchButtonPressed}> Match with a Lawyer
              </button>
            </div>
          </div>
          )}
          {spanishLanguage && (
          <div className="column">
            {organizationsList.length <1 && (
            <div>
              <p
                className="paragraph has-text-weight-bold py-3 is-size-4 has-text-centered"
              >
                Consejo para obtener mejores respuestas!
              </p>
              <ul>
                <li className="px-5 is-size-5">
                  &#x2022; No es necesario dar información personal!
                </li>
                <li className="px-5 is-size-5">
                  &#x2022; A más información, mejores resultados!
                </li>
              </ul>
              <br />
              <p className="paragraph is-size-5">Ejemplo 1:</p>
              <p className="paragraph has-text-justified is-size-5">
                "He contactado a mi  arrendador hace dos semanas porque la ducha no funciona y aun no recibo respuesta. ¿Qué debo hacer?"
              </p>
              <br />
              <p className="paragraph is-size-5">Ejemplo 2:</p>
              <p
                className="paragraph has-text-justified is-size-5"
              >
                "¿Cuáles son los pasos para obtener un divorcio?"
              </p>
            </div>
            )}

            {organizationsList.length >0 && (
              <ShowOrganizationsList
              list = {organizationsList}
              />
            )}

            <br />
            <ShowMetrics
            answers = {answers}
            />
          
            <div className="column has-text-centered">
              <button id="matchButton" className="button is-black is-medium" onClick = {MatchButtonPressed}> Conéctame con un abogado
              </button>
            </div>
          </div>
          )}



        </div>
      </section>
      
      <section className="termsOfService">
        {!spanishLanguage && (
        <div>
          <p className="has-text-weight-bold has-text-centered pt-5">Adibilis Terms of Service</p>
          <p className="has-text-centered">Last updated: 03/23/2023</p>
          <br />
          <div className="columns">
            <div className = "column is-one-fifth"></div>
            <div className = "column">
              <p className = "has-text-justified"> The information provided on this website does not, and is not intended to, constitute legal advice; instead, all information, content, and materials available on this site are for general informational purposes only. 
                Readers of this website should contact their attorney to obtain advice with respect to any particular legal matter.  No reader, user, or browser of this site should act or refrain from acting on the basis of information on this site without first seeking legal advice from counsel in the relevant jurisdiction.  Only your individual attorney can provide assurances that the information contained herein – and your interpretation of it – is applicable or appropriate to your particular situation.  Use of, and access to, this website or any of the links or resources contained within the site do not create an attorney-client relationship between the reader, user, or browser and website authors, and their respective employers.</p>
            </div>
            <div className = "column is-one-fifth"></div>
          </div>
        </div>
        )}

        {spanishLanguage && (
        <div>
          <p className="has-text-weight-bold has-text-centered pt-5">Términos de Servicio de Adibilis</p>
          <p className="has-text-centered">Última actualización: 23/03/2023</p>
          <br />
          <div className="columns">
            <div className = "column is-one-fifth"></div>
            <div className = "column">
              <p className = "has-text-justified">
                La información provista en este sitio web no constituye ni pretende constituir asesoramiento legal; en cambio, toda la información, el contenido y los materiales disponibles en este sitio web son solo para fines informativos generales.
                Los lectores de este sitio web deben comunicarse con su abogado para obtener asesoramiento con respecto a cualquier asunto legal en particular. Ningún lector, usuario o navegador de este sitio debe actuar o abstenerse de actuar sobre la base de la información de este sitio sin buscar primero el asesoramiento legal de un abogado en la jurisdicción correspondiente. Solo su abogado puede garantizar que la información contenida en este documento, y la interpretación de la misma, es aplicable o apropiada para su situación particular. El uso y el acceso a este sitio web o cualquiera de los enlaces o recursos contenidos en el sitio no crean una relación de cliente-abogado entre el lector, el usuario o el navegador y los autores del sitio web, y sus respectivos empleadores.</p>
            </div>
            <div className = "column is-one-fifth"></div>
          </div>
        </div>
        )}

        <div className="columns px-5 py-3">
          <div className="column has-text-left">
            <p className="has-text-weight-bold">AdIbilis</p>
            <p>2022 - 2023</p>
            <p>Privacy - Terms</p>
          </div>
          <div className="column has-text-right">
            <p className="has-text-weight-bold">Follow us on</p>
            <p id ="p1">Instagram</p>
            <p id = "p1">Facebook</p>
          </div>
        </div>
      </section>

    </>
  );
};

export default Home;
