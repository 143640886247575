import React from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import mixpanel from 'mixpanel-browser';


/**
 * Log the user in. Grab username and send it in an object to POST /api/login. Call `props.onLogin()` to have App update your sign-in status, and then call `navigate('/user-home')`
 */

const LoginPage = props => {
  const navigate = useNavigate();
  //const [sessionOwner, setSessionOwner] = useState([])

  // Mixpanel Project Token
  // mixpanel.init('1886cef355765a12341d524e109beac5', {debug: true});
  

  const loginWithEmail = e => {
    e.preventDefault();
    // I should update this with "StyledFirebaseAuth" so I don't have to read the password manually
    const auth = getAuth();
    const emailText = e.target.email.value;
    const passwordText = e.target.password.value;
    e.target.email.value = ""
    e.target.password.value = ""
    signInWithEmailAndPassword(auth, emailText, passwordText)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        //console.log("user log in: ", user.uid);
        const idtoken = user.getIdToken().then((idtoken) => {
        console.log("idtoken: ", idtoken);

        fetch("/api/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            idtoken: idtoken
          })
        })
          .then(res => {
            if (res.ok) { //same as using res.status === 200
              res.json().then(data => {
                console.log("Got back ok response");
                console.log("idtoken: ", data.idtoken);
                props.onLogin(); // this changes the sign-in status
                navigate("/user-home");
                //mixpanel.identify(data.idtoken);
                mixpanel.track("User logged in", {"Firebase idtoken": data.idtoken});

              })
            } else {
              console.log("Login went wrong");
            }
          })
        })
      })
        //setSessionOwner(user.uid)

      
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("error: ", errorCode, errorMessage);
        window.alert(errorMessage);
      });
    }

    const loginWithGoogle = () => {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then((result) => {
          // Change the sign-in status and navigate to the user home page
        props.onLogin(); 
        navigate("/user-home");

        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const accesstoken = credential.accessToken;

        // The signed-in user info.
        const user = result.user;
        console.log("user log in: ", user.uid);

        // Send Firebase ID token to backend
        const idtoken = user.getIdToken().then((idtoken) => {
          console.log("idtoken: ", idtoken);

          fetch("/api/login", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              idtoken: idtoken
            })
          })
            .then(res => {
              if (res.ok) { //same as using res.status === 200
                res.json().then(data => {
                  console.log("Got back ok response");
                  console.log("username: ", data.username);
                })
              } else {
                console.log("Login went wrong");
              }
            })
        })
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log("error: ", errorCode, errorMessage);
          // The email of the user's account used.
          //const email = error.customData.email;
          // The AuthCredential type that was used.
          //const credential = GoogleAuthProvider.credentialFromError(error);
        });
    }


  return (
    <section className="hero">
      <div className="container hero-body has-text-centered">
        <br></br>
        <h1 className="title">Login to Adibilis </h1>
        <form onSubmit={loginWithEmail}> 
          <br></br>
          <p className="subtitle"> Log in with Email</p>
          <label className="label has-text-left" htmlFor="username">
            Email
          </label>
          <input name = "email" className="input"/> 
          <label className="label has-text-left" htmlFor="password">
            Password
          </label>
          <input name = "password" className="input" type = "password"/> 
          <div>
            <br></br>
            <input type = "submit" className="button is-black"/>
          </div>
        </form>
        <br></br><br></br>
        <button className="button" onClick={loginWithGoogle}>Log in with Google</button>
      </div>
    </section>
  );
};

export default LoginPage;
