import React, { useEffect, useState } from "react";
import ListSessions from "../components/ListSessions";
import { getAuth } from "firebase/auth";
import {db} from "../config/firebase-config";
import {doc, addDoc, updateDoc, arrayUnion, collection, setDoc, query, where, getDocs} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

/**
 *
 * When this component loads,
 * load the users sessions with questions with a GET /api/sessions
 * Save it to the component state.
 *
 */
const UserHomePage = props => {
  const [sessions, setSessions] = useState([]);
  let [sessionFirestoreId, setSessionFirestoreId] = useState([]);
  const navigate = useNavigate();

  // Load the sessions with questions when the component first loads
  useEffect(() => {
    const auth = getAuth();

    const q = query(collection(db, "sessions"), where("owner", "==", auth.currentUser.uid));
    let sessionsQuery = [];
    let firestoreIds = [];

    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        sessionsQuery.push(doc.data());
        firestoreIds.push(doc.id);
      });
      console.log("user previous conversations", sessionsQuery)
      setSessions(sessionsQuery);
      setSessionFirestoreId(firestoreIds);
    });

    // fetch('/api/sessions', {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json'
    //     }
    //   },
    //   {credentials: 'include'})
    // .then(response => {
    //   if (response.ok) {
    //     response.json().then(data => {
    //       console.log("data from api/sessions", data)
    //       setSessions(data);
    //     });
    //   }
    // })
  }, []);


  // Refresh list of conversations (sessions)
  const onSessionEliminated = (sessionCode, firestoreId) => {
    console.log("On session eliminated (update UI)");

    let allSessions  = [...sessions]
    let allFirestoreIds = [...sessionFirestoreId]
    // eliminate sessionCode from allSessions
    let filteredSessions = allSessions.filter(session => session.id !== sessionCode)
    let filteredFirestoreIds = allFirestoreIds.filter(id => id !== firestoreId)

    setSessions(filteredSessions)
    setSessionFirestoreId(filteredFirestoreIds)
    console.log("Sessions in Home after eliminating:", sessionCode, "with Firestore Id:", firestoreId, ". The remaining sessions are: ", filteredSessions)
  }

  const StartNewConversation = ()=>{
    // Send user to user home page
    navigate("/")
  }


  return (
    <>
      <section>
        <div className="container">
          <ListSessions 
          sessions={sessions}
          firestoreIds = {sessionFirestoreId} 
          onSessionEliminated={onSessionEliminated}
          />
        </div>
        <div className="column has-text-centered">
          <button id="matchButton" className="button is-black" onClick = {StartNewConversation}> New conversation
          </button>
        </div>
      </section>
    </>
  );
};

export default UserHomePage;
