import React from "react";
import { Link, useNavigate } from "react-router-dom";

const NavBar = props => {
  const navigate = useNavigate();

  const moveToSection = (section) => {
    const sectionToGo = document.querySelector(`#${section}`)
    if (!sectionToGo) {
      //if not in Home, navigate to home
      navigate("/")
      // window.scrollTo doesn't work if the page is not loaded yet. I have tried to use window.onload, but it doesn't work
      /*
      window.onpageshow = (event) => {
        window.scrollTo({
          top: document.querySelector(`#${section}`).offsetTop,
          behavior: "smooth",
        })
      }
      */
    } else {
      window.scrollTo({
      top: sectionToGo.offsetTop,
      behavior: "smooth",
      })
    };
  }

  const handleClickBurger = (e) => {
    const dropDownMenu = Array.prototype.slice.call(document.querySelectorAll('.navbar-menu'), 0);

    dropDownMenu.forEach( el => {
      el.classList.toggle('is-active');
    });
  };


  return (
    <nav className="navbar is-fixed-top" style={{ paddingTop: "5px"}}>
      <div
        className="container"
        style={{ paddingLeft: "32px"}}
      >
        <div className="navbar-brand">
          <div className="navbar-item">
            <button 
              id="adibilisButton"
              className="button is-white has-text-weight-bold is-size-6"
              onClick = {(e)=>{
                e.preventDefault();
                moveToSection("Welcome")
              }}
            >
              <span style = {{color: "dodgerblue"}}>A</span>
              <span>D</span>
              <span style = {{color: "dodgerblue"}}>I</span>
              BILIS
            </button>
          </div>
          <a
            role="button"
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarMenu"
            onClick={handleClickBurger}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarMenu" className="navbar-menu">
          < div className="navbar-start">
            <div className="navbar-item">
              <button
                id="askQuestionButton"
                className="button is-white has-text-weight-bold is-size-6"
                onClick = {(e)=>{
                  e.preventDefault();
                  moveToSection("Chatbot")
                }}
              >
                Ask a question
              </button>
            </div>

            {props.isSignedIn && (
              <div id="navbarMenu" className="navbar-menu" >
                <div className="navbar-item">
                  <Link to="/user-home">
                    <p className="button is-white has-text-weight-bold is-size-6 ">Previous questions</p>
                  </Link>
                </div>
              </div>
            )}

            <div id="navbarMenu" className="navbar-menu" >
              <div className="navbar-item">
                <Link to="/ContactUs">
                  <p className="button is-white has-text-weight-bold is-size-6 ">Contact Us</p>
                </Link>
              </div>
            </div>

          </div>
        </div>
          

        {props.isSignedIn && (
          <div id="navbarMenu" className="navbar-menu" >
            <div className="navbar-end" style = {{paddingRight: "32px"}}>
              <div className="navbar-item">
                <a className="button mt" href="/logout">
                  Logout
                </a>
              </div>
            </div>
          </div>
        )}
          
        {!props.isSignedIn && (
          <div id="navbarMenu" className="navbar-menu" >
            <div className="navbar-end" style = {{paddingRight: "32px" }}>
              <div className="navbar-item">
                <a href="/user-home" className="button is-black">
                  Log In
                </a>
              </div>
              <div className="navbar-item">
                <a href="/signup" className="button is-black">
                  Sign Up
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
