import ShowEachQuestionAnswer from "./ShowEachQuestionAnswer";

const ListQuestionsAnswers = props => {

  console.log("listing Questions and answers")

  return (
      <div style={{ overflowY: "scroll", height: "400px"}} id = "chatbot-messages">
        {props.questions.length > 0 &&
          props.questions
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((question, index) => (
              <ShowEachQuestionAnswer
                key={index}
                question={question}
                answer = {props.answers[index]}
                sessionCode={props.sessionCode}
              />
            ))}
      </div>
  );
};

export default ListQuestionsAnswers;
