import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {db} from "../config/firebase-config";
import {doc, addDoc, updateDoc, arrayUnion, collection, setDoc, query, where, getDocs} from "firebase/firestore";
import ListQuestionsAnswers from "../components/ListQuestionsAnswers";
import CreateQuestion from "./../components/CreateQuestion";
import ShowMetrics from "../components/ShowMetrics";
import ShowOrganizationsList from "../components/ShowOrganizationsList";
import { getAuth } from "firebase/auth";


/**
 * Allow users to ask questions and get responses
 * which will be store in the session.
 *
 * When this component first loads, grab the questions and answers
 * for this session code by making a
 * GET /api/session/:session-code request
 *
 * If it is successful, save the questions from the request
 * into the state.
 *
 * If a user submits a question, reload the latest questions from the server
 */
const ClassSessionPage = props => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const { sessionCode } = useParams();
  //const [updateQuestions, setUpdateQuestions] = useState(false);
  //const [updateAnswers, setUpdateAnswers] = useState(false);
  const [firestoreId, setFirestoreId] = useState("");
  const [organizationsList, setOrganizationsList] = useState([]);
  const [spanishLanguage, setSpanishLanguage] = useState(false); 

  // Load the questions when the component first loads
  useEffect(() => {
    //const auth = getAuth();
    // const q = query(collection(db, "sessions"), where("id", "==", sessionCode), where("owner", "==", auth.currentUser.uid));
    const q = query(collection(db, "sessions"), where("id", "==", sessionCode));

    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log("OnQuestionCreated:" , doc.data().questions)
        setQuestions(doc.data().questions);
        setAnswers(doc.data().answers)
        setFirestoreId(doc.id)

        const chatbot = document.querySelector("#chatbot-messages");
        chatbot.scrollTo({
          top: chatbot.scrollHeight,
          behavior: "smooth",
        })
      });
    });


    // fetch(`/api/session/${sessionCode}`)
    //   .then(response => {
    //     if (response.ok) {
    //       response.json().then(data => {
    //         setQuestions(data.questions);
    //         setAnswers(data.answers)
    //       });
    //     }
    //   }
    //   );
  }, []);

  
  // Refresh list of questions and answers from Firestore
  // const onQuestionCreated = (sessionCode, allQuestions, allAnswers) => {
  //   console.log("On question created (update UI)");
  //   // Update the list of Q&As
  //   setQuestions(allQuestions);
  //   setAnswers(allAnswers)
  //   const chatbot = document.querySelector("#chatbot-messages");
  //   chatbot.scrollTo({
  //     top: chatbot.scrollHeight,
  //     behavior: "smooth",
  //   })
  // }


  // Refresh list of questions
  const onQuestionCreated = (sessionCode, newQuestion) => {
    console.log("On question created (update UI)");

    // Fix because user can refresh the website and continue asking
    if (!props.isSignedIn && questions.length === 2) {
      window.alert("You have reached the maximum number of questions. Please sign up to ask more questions.")
    }
    else {
      console.log("Set questions")
      // Update the list of Q&As
      if (newQuestion){
        let allQuestions = [...questions];
        //let allQuestions = questions;
        allQuestions.push(newQuestion);
        setQuestions(allQuestions);
        console.log("Questions in Home:", allQuestions)

        //setUpdateQuestions(!updateQuestions)
      }
      
      const chatbot = document.querySelector("#chatbot-messages");
      chatbot.scrollTo({
        top: chatbot.scrollHeight,
        behavior: "smooth",
      })
    }
  };

  // Refresh list of answers
  const onAnswerCreated = (sessionCode, newAnswer) => {
    console.log("On answer created (update UI)");
    if (newAnswer) {
      let allAnswers = [...answers];
      //let allAnswers = answers;
      allAnswers.push(newAnswer);
      setAnswers(allAnswers)
      console.log("Answers in Home:", allAnswers)

      //setUpdateAnswers(!updateAnswers)
    }
    const chatbot = document.querySelector("#chatbot-messages");
    chatbot.scrollTo({
      top: chatbot.scrollHeight,
      behavior: "smooth",
    })
  }

  // Refresh list of organizations
  const onOrganizationsListCreated = (organizationsList) => {
    console.log("On organizations list created (update UI)");
    setOrganizationsList(organizationsList)
    console.log("Organizations List in Home:", organizationsList)
  }

  const MatchButtonPressed = ()=>{
    if (props.isSignedIn){
      // Sent alert that user will receive a message
      window.alert("You will receive an email shortly with a lawyer that matches your needs")
      // Signal us that we need to match this user with a lawyer
      /*fetch(`/api/matchLawyer/${sessionCode}`)
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            console.log("matchLawyer info: ", data)
          });
        }
      });*/
      addDoc(collection(db, "Lawyer Matching"), {
        session_id: sessionCode,
        date: Date(Date.now).toString(),
      }).then((docRef) => {
        console.log("Client asked to match with a lawyer: ")
      });
    } else {
      // Sent alert that user needs to sign in first
      window.alert("You need to sign in first")
    }
  }

  const EnglishButtonPressed = ()=>{
    console.log("English button pressed")
    // Change is-active between English and Spanish tabs
    document.getElementById("EnglishButton").className = "is-active"
    document.getElementById("SpanishButton").className = ""
    setSpanishLanguage(false)
  }

  const SpanishButtonPressed = ()=>{
    console.log("Spanish button pressed")
    // Change is-active between English and Spanish tabs
    document.getElementById("SpanishButton").className = "is-active"
    document.getElementById("EnglishButton").className = ""
    setSpanishLanguage(true)
  }
  

  return (

    <>
      <section className = "section is-medium" id = "Chatbot">
        <p className="title has-text-centered">Ask any legal question!</p>
        <div className="columns">
          <div className="column">
            <div className="box has-background-info-light" style = {{height: "550px"}}>
              <ListQuestionsAnswers
                sessionCode={sessionCode}
                questions={questions}
                answers = {answers}
              />
              <CreateQuestion
              sessionCode={sessionCode}
              onQuestionCreated={onQuestionCreated}
              onAnswerCreated={onAnswerCreated}
              onOrganizationsListCreated={onOrganizationsListCreated}
              firestoreId = {firestoreId}
              spanishLanguage = {spanishLanguage}
              />
            </div>
          </div>
          <div className="column">
          {organizationsList.length <1 && (
            <div>
              <p
                className="paragraph has-text-weight-bold py-3 is-size-4 has-text-centered"
              >
                Tips to get better answers!
              </p>
              <ul>
                <li className="px-5 is-size-5">
                  &#x2022; No need to give any personal information!
                </li>
                <li className="px-5 is-size-5">
                  &#x2022; The more context, the better results!
                </li>
              </ul>
              <br />
              <p className="paragraph is-size-5">Example 1:</p>
              <p className="paragraph has-text-justified is-size-5">
                "I have contacted my landlord two weeks ago because my shower broke
                but I haven't got a response. What should I do?"
              </p>
              <br />
              <p className="paragraph is-size-5">Example 2:</p>
              <p
                className="paragraph has-text-justified is-size-5"
              >
                What recommendations to represent myself in traffic court?
              </p>
              <br />
              <p className="paragraph is-size-5">Example 3:</p>
              <p
                className="paragraph has-text-justified is-size-5"
              >
                "How can I file for a divorce?"
              </p>
            </div>
            )}

            {organizationsList.length >0 && (
              <ShowOrganizationsList
              list = {organizationsList}
              spanishLanguage = {spanishLanguage}
              />
            )}

            <br />
            <ShowMetrics
              answers = {answers}
              spanishLanguage = {spanishLanguage}
            />

            <div className="column has-text-centered">
              <button id="matchButton" className="button is-black is-medium" onClick = {MatchButtonPressed}> Match with a Lawyer
              </button>
            </div>  
          </div>
        </div>
      </section>

      <section className="termsOfService">
      <div>
        <p className="has-text-weight-bold has-text-centered pt-5">Adibilis Terms of Service</p>
        <p className="has-text-centered">Last updated: 03/23/2023</p>
        <br />
        <div className="columns">
          <div className = "column is-one-fifth"></div>
          <div className = "column">
            <p className = "has-text-justified"> The information provided on this website does not, and is not intended to, constitute legal advice; instead, all information, content, and materials available on this site are for general informational purposes only. 
              Readers of this website should contact their attorney to obtain advice with respect to any particular legal matter.  No reader, user, or browser of this site should act or refrain from acting on the basis of information on this site without first seeking legal advice from counsel in the relevant jurisdiction.  Only your individual attorney can provide assurances that the information contained herein – and your interpretation of it – is applicable or appropriate to your particular situation.  Use of, and access to, this website or any of the links or resources contained within the site do not create an attorney-client relationship between the reader, user, or browser and website authors, and their respective employers.</p>
          </div>
          <div className = "column is-one-fifth"></div>
        </div>
        
      </div>
      

      <div className="columns px-5 py-3">
        <div className="column has-text-left">
          <p className="has-text-weight-bold">AdIbilis</p>
          <p>2022 - 2023</p>
          <p>Privacy - Terms</p>
        </div>
        <div className="column has-text-right">
          <p className="has-text-weight-bold">Follow us on</p>
          <p id ="p1">Instagram</p>
          <p id = "p1">Facebook</p>
        </div>
      </div>
    </section>

    </>

    
  );
};

export default ClassSessionPage;
