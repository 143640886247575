/**
 * Create a question for a session
 *
 * A user can provide the content of their question. 
 * When they submit the form, make a
 * POST /api/session/:session-code/question
 * with the value of the input in the body of the request.
 *
 * If it is successful, call `props.onQuestionCreated()`
 * to tell the parent component to refresh the view
 */

import React, { useState, useEffect, useRef } from "react";
import {db} from "../config/firebase-config";
import {doc, addDoc, updateDoc, arrayUnion, collection, setDoc, query, where, getDocs} from "firebase/firestore";
// For speech recognition
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
// For microphone icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons'
import { faStop } from '@fortawesome/free-solid-svg-icons'
import mixpanel from "mixpanel-browser";

const CreateQuestion = props => {
  let [newSessionFirestoreId, setNewSessionFirestoreId] = useState("");
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    let timeoutId;
    if (isListening) {
      setTimeout(() => {
        SpeechRecognition.startListening({ continuous: true });
        timeoutId = setTimeout(() => {
          SpeechRecognition.stopListening();
          setIsListening(false);
        }, 10000); // stop after 10 seconds
      }, 2000);
    } else {
      clearTimeout(timeoutId);
      SpeechRecognition.stopListening();
    }
  }, [isListening]);

  useEffect(() => {
    inputRef.current.value = transcript;
  }, [transcript]);
  

  const createQuestionAnswer = (sessionCode, questionText, firestoreId) => {
    console.log("Question created: ", questionText);
    
    let answerText = ""
    let allQuestions = []
    let allAnswers = []
    // Make ask button look like it is loading
    let submitQuestionButton = document.getElementById('sendQuestionButton')
    submitQuestionButton.classList.add("is-loading")
      
    // Read questions and answers from Firestore
    const q = query(collection(db, "sessions"), where("id", "==", sessionCode));
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log("Questions read form Firestore:" , doc.data().questions)
        // allQuestions.push(doc.data().questions);
        // allAnswers.push(doc.data().answers)
        allQuestions = doc.data().questions;
        allAnswers = doc.data().answers
      });

      // Add new question to array
      allQuestions.push(questionText)
      allAnswers.push("")

      // Update the UI (with question)
      //props.onQuestionCreated(sessionCode, questionText, answerText);


      // Fetch backend to generate answer with OpenAI
      fetch(`/api/session/${sessionCode}/answerWithOpenAI`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          questions: allQuestions,
          answers: allAnswers
        }),
      }).then((response) => {
        if (response.ok) {
          response.json().then(data => {
            //let answerText = data.answer;
            answerText = data.answer;
            console.log("Answer created WITH BACKEND: ", answerText);

            // Add new answer to array
            //allAnswers.push(answerText)
            allAnswers[allAnswers.length - 1] = answerText
            console.log("allAnswers: ", allAnswers)

            // Update the UI (with answer generated)
            props.onAnswerCreated(sessionCode, answerText);
            // Make ask button stop loading
            submitQuestionButton.classList.remove("is-loading")

            // If first question, search for the title in the last paragraph of the answer
            let title = ""
            if (allQuestions.length === 1) {
              const paragraphs = answerText.split("\n\n")
              const lastParagraph = paragraphs[paragraphs.length - 1]
              const titleSentence = lastParagraph.split(". ").pop()
              // Search for word "title" and get the rest of the sentence
              if (titleSentence.includes("Title:")) {
                title = titleSentence.split("Title: ").pop()
              }
            }


            // Fetch backend to generate organization list with OpenAI
            // if (allQuestions.length === 1) {
            //   fetch(`/api/session/${sessionCode}/organizationsListWithOpenAI`, {
            //     method: "POST",
            //     headers: {
            //       "Content-Type": "application/json",
            //     },
            //     body: JSON.stringify({
            //       question: questionText,
            //     }),
            //   }).then((response) => {
            //     if (response.ok) {
            //       response.json().then(data => {
            //         //let answerText = data.answer;
            //         answerText = data.answer;
            //         console.log("Organization list created WITH BACKEND: ", answerText);

            //         // Update the UI (with organization list generated)
            //         props.onOrganizationsListCreated(answerText);
            //       })
            //     }
            //   })
            // }

            // Update questions and answers in the database in Firebase
            console.log("Firestore ID to update Q&As as docRec: ", firestoreId)
            let sessionToUpdateRef = doc(db, 'sessions', firestoreId)

            updateDoc(sessionToUpdateRef, {
              questions: arrayUnion(questionText),
              answers: arrayUnion(answerText),
              title: title
            })
            .then(() => {
              console.log("Q&As updated in database Firestore")
            })
            .catch((error) => {
              console.error("Error updating Q&As in database Firestore: ", error);
            });
          }
        )}
      })

      // Fetch backend to generate organization list with OpenAI
      if (allQuestions.length === 1) {
        fetch(`/api/session/${sessionCode}/organizationsListWithOpenAI`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            question: questionText,
          }),
        }).then((response) => {
          if (response.ok) {
            response.json().then(data => {
              //let answerText = data.answer;
              answerText = data.answer;
              console.log("Organization list created WITH BACKEND: ", answerText);

              // Update the UI (with organization list generated)
              props.onOrganizationsListCreated(answerText);
            })
          }
        })
      }



    })
  }
  
  
  
  const handleSubmitQuestion = e => {
    e.preventDefault();

    console.log("createQuestion question submiteed:", e.target.question.value);
    // Set input to empty string
    const questionText = e.target.question.value;
    e.target.question.value = "";

    // Track question event with Mixpanel
    mixpanel.track("User asked a question", {"Question": e.target.question.value});

    if (props.sessionCode.length === 0) {
    // Create a new session
      fetch(`/api/createSession`)
        .then(response => {
          if (response.ok) {
            response.json().then(data => {
              console.log("Session code created for new chat: ", data.id, " for user: ", data.owner)
              const sessionCode = data.id
              console.log("createQuestion session code: ", sessionCode);

              // Update the UI (with question)
              props.onQuestionCreated(sessionCode, questionText);
              
              // Add session with code generated by Firestore
              addDoc(collection(db, "sessions"), {
                owner : data.owner,
                id: data.id,
                //name: data.name,
                createdAt: data.createdAt,
                questions: data.questions,
                answers: data.answers,
              }).then((docRef) => {
                // Get the id from Firestore to update it when questions and answers are added
                console.log("Document written with Firestore ID: ", docRef.id);
                
                //firestoreIdRef.current = docRef.id
                setNewSessionFirestoreId(() => {
                  return docRef.id
                })
                // Save question and answer in the session created in the databse in Firestore
                createQuestionAnswer(sessionCode, questionText, docRef.id);
              })
            });
          }
        })
    } else {
      // Use existing session
      const sessionCode = props.sessionCode

      // Update the UI (with question)
      props.onQuestionCreated(sessionCode, questionText);

      // If there is session but not Firestore ID, use the one from props
      if (newSessionFirestoreId === "") {
        newSessionFirestoreId = props.firestoreId
      }

      console.log("Firestore ID obtained and set: ", newSessionFirestoreId)
      createQuestionAnswer(sessionCode, questionText, newSessionFirestoreId);
    };
  };

  return (
    <>
      <form
        className="box has-background-info-light flex" 
        onSubmit={handleSubmitQuestion} style={{ marginTop: "20px" }}
      >
        <button className="button is-black" type="button" onClick={() => setIsListening(prevState => !prevState)}>
          <FontAwesomeIcon icon={isListening ? faStop : faMicrophone} />
        </button>
        
        
          
        {!props.spanishLanguage && (
        <>
          <input ref={inputRef} className="input" type="text" name="question" placeholder="Your question ..."
          style={{ width: "75%" }}/>
          <input className="button is-black" type="submit" value="Ask" id="sendQuestionButton"/>
        </>
        )}
        {props.spanishLanguage && (
        <>
          <input ref={inputRef} className="input" type="text" name="question" placeholder="Tu pregunta aquí ..."
          style={{ width: "70%" }}/>
          <input className="button is-black" type="submit" value="Enter" id="sendQuestionButton"/>
        </>
        )}
      </form>
    </>
  );
};
export default CreateQuestion;
