
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../config/firebase-config";

const ShowSession = props => {
  //const [currentlyFirstQuestion, setCurrentlyFirstQuestion] = useState(props.sessionInfo.questions[0].question);
  const currentlyFirstQuestion = props.sessionInfo.questions[0]

  console.log("first question of that Session: ", currentlyFirstQuestion)

  const handleEliminate = () => {
    // Alert the user of the elimination and ask him to confirm
    if (window.confirm("Are you sure you want to eliminate this conversation?")) {
      // Soft delete in which we only change the owner of the session to "deletedByUser"
      let sessionToUpdateRef = doc(db, 'sessions', props.firestoreId)
      updateDoc(sessionToUpdateRef, {
        owner: "deletedByUser" + Math.floor(Math.random() * 1000000),
      })
      .then(() => {
        console.log("eliminate conversation with session:", props.sessionInfo.id, "and firestore id:", props.firestoreId)
        // Update the UI
        props.onSessionEliminated(props.sessionInfo.id, props.firestoreId)
      })
      .catch((error) => {
        console.error("Error soft deleting conversation in Firestore: ", error);
      });
    }
  }


  return (
    <div
      style={{
        borderTop: "1px solid darkgrey",
        paddingTop: "15px",
        paddingBottom: "15px",
      }}
    >
      <div className="container">
        <div className = "content">
          <h2>Session: {props.sessionInfo.title}</h2>
          <button className = "delete" style={{ position: "absolute", top: "2px", right: "2px" }} onClick = {handleEliminate}></button>
          {props.sessionInfo.id.length > 0 && (
            <div>
              {currentlyFirstQuestion && (
                <div>
                  <a href={`/${props.sessionInfo.id}`}>
                    View the chat stored for:  {currentlyFirstQuestion}
                  </a>
                  <p>
                    Created at:{" "}
                    {(props.sessionInfo.createdAt).slice(0, 10)}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ShowSession;
