/**
 * Show metrics
 *
 */
const ShowMetrics = props => {
  
  let metrics = []
  let legalSector = "---"
  console.log("answer in ShowMetrics: ", props.answers)
  let chatbotResponse = props.answers;
  const paragraphs = String(chatbotResponse).split("\n\n");
  console.log("paragraps in ShowMetrics: ", paragraphs)

  if (paragraphs.length > 1){
    let scoreSentence = paragraphs.pop();
    legalSector = scoreSentence.match(/Legal Sector:\s+(\w+)/i);
    if (!legalSector){
      legalSector = "---"
    } else {
      legalSector = legalSector[1]
    }

    metrics = scoreSentence.match(/-?\d+(?:,\d+)*(?:\.\d+)?/g); // get the numbers
    if (!metrics){
      metrics = []
    }
  }

  console.log("metrics: ", metrics)

  return (
    <>
    {!props.spanishLanguage && (
    <>
      <p className="paragraph has-text-weight-bold py-3 is-size-4 has-text-centered">
          Some metrics to guide your decisions!
      </p>

      <div className="columns">
        <div className="column">
          <div
            className="box has-text-centered has-background-info-light is-half"
          >
            {metrics.length === 0  && (
            <span id="legalSectorText">Legal Sector: --- </span>
            )}
            {metrics.length > 0 && (
            <span id="legalSectorText">Legal Sector: {legalSector} </span>
            )}
          </div>
        </div>
        <div className="column">
          <div
            className="box has-text-centered has-background-info-light is-half"
          >
            {metrics.length === 0  && (
            <span id="urgencyScoreText">Legal Complexity: --- </span>
            )}
            {metrics.length > 0 && (
            <span id="urgencyScoreText">Legal Complexity: {metrics[0]} /10 </span>
            )}
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
            <div
              className="box has-text-centered has-background-info-light is-half"
            >
              {metrics.length === 0 && (
              <span id="costConflictText">Legal cost: --- </span>
              )}
              {metrics.length > 0 && (
              <span id="costConflictText">Legal cost: ${metrics[2]} - ${metrics[3]} </span>
              )}
            </div>
          </div>
        <div className="column">
          <div
            className="box has-text-centered has-background-info-light is-half"
          >
            {metrics.length === 0 && (
            <span id="timeConflictText">Time: --- </span>
            )}
            {metrics.length > 0 && (
            <span id="timeConflictText">Time: {metrics[4]} {metrics[5]} months </span>
            )}
          </div>
        </div>
      </div>
    </>
    )}

    {props.spanishLanguage && (
      <>
      <p className="paragraph has-text-weight-bold py-3 is-size-4 has-text-centered">
          Métricas para guiar tus decisiones!
      </p>

      <div className="columns">
        <div className="column">
          <div
            className="box has-text-centered has-background-info-light is-half"
          >
            {metrics.length === 0  && (
            <span id="legalSectorText">Sector Legal: --- </span>
            )}
            {metrics.length > 0 && (
            <span id="legalSectorText">Sector legal: {legalSector} </span>
            )}
          </div>
        </div>
        <div className="column">
          <div
            className="box has-text-centered has-background-info-light is-half"
          >
            {metrics.length === 0  && (
            <span id="urgencyScoreText">Complejidad Legal: --- </span>
            )}
            {metrics.length > 0 && (
            <span id="urgencyScoreText">Complejidad Legal: {metrics[0]} /10 </span>
            )}
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
            <div
              className="box has-text-centered has-background-info-light is-half"
            >
              {metrics.length === 0 && (
              <span id="costConflictText">Costo legal: --- </span>
              )}
              {metrics.length > 0 && (
              <span id="costConflictText">Costo Legal: ${metrics[2]} - ${metrics[3]} </span>
              )}
            </div>
          </div>
        <div className="column">
          <div
            className="box has-text-centered has-background-info-light is-half"
          >
            {metrics.length === 0 && (
            <span id="timeConflictText">Tiempo: --- </span>
            )}
            {metrics.length > 0 && (
            <span id="timeConflictText">Tiempo: {metrics[4]} {metrics[5]} meses </span>
            )}
          </div>
        </div>
      </div>
    </>
    )}
    </>
  );
};

export default ShowMetrics;
