import ShowSession from "./ShowSession";

const ListSessions = props => {

  console.log("List Sessions in ListSessions.js", props.sessions)
  console.log("Firestore IDs list:", props.firestoreIds)
  console.log("Length List Sessions: ", props.sessions.length)
  //console.log("Sessions Username: ", props.sessions[0].owner)

  return (
    <>
      <div className="has-text-centered mb-5">
        <h1 className="title">Previous Conversations</h1>
      </div>
      <div
        style={{
          marginLeft: "25%",
          marginRight: "25%",
        }}
      >
        {props.sessions.length === 0 && (
          <h1 className="title">No Previous Conversations</h1>
        )}
        {props.sessions.length > 0 && 
          props.sessions.map((sessionInfo, index) => {
            console.log("one of the sessionInfo", sessionInfo)
            return (
              <ShowSession
                key={index}
                sessionInfo={sessionInfo}
                firestoreId = {props.firestoreIds[index]}
                onSessionEliminated={props.onSessionEliminated}
              />
            );
          })}
      </div>
    </>
  );
};

export default ListSessions;
