import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import UserHomePage from "./pages/UserHomePage";
import ClassSessionPage from "./pages/ClassSessionPage";
import Navbar from "./components/Navbar";
import SignUpPage from "./pages/SignUpPage";
import ContactUs from "./pages/ContactUs";
import firebaseui from "firebaseui";
import firebase from "firebase/app";

/**
 * App contains all the pages for this web application
 *
 * There are 5 pages
 * - /home
 * - /login
 * - /signup
 * - /logout
 * - /user-home
 *
 * When the App loads for the first time, make a
 * GET /api/user to see if the user is signed in.
 *
 * If you receive a 401, set the isSignedIn state
 * to false.
 *
 * If you receive a 200, set the isSignedIn state to true
 * and then set the user state to the user object
 * from the response of the request.
 *
 */
const App = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [user, setUser] = useState(null);

  const requiresLogin = Component => {
    return isSignedIn ? Component : <Navigate to="/login" />;
  };

  useEffect(() => {
    console.log("Loading App for the first time");

    fetch("/api/user")
      .then(res => {
        if (res.status === 200) {
          return res.json().then(data => {
            setIsSignedIn(true);
            setUser(data);
          }) 
        } else {
          setIsSignedIn(false);
        }
      })

  }, []);

  return (
    <div>
      <Router>
        <Navbar isSignedIn={isSignedIn} username={user?.userId ?? null} />
        <Routes>
          <Route path="/user-home" element={requiresLogin(<UserHomePage />)}/>
          <Route path="/login" element={<LoginPage onLogin={() => {setIsSignedIn(true);}}/>}/>
          <Route path="/signup" element={<SignUpPage onLogin={() => {setIsSignedIn(true);}}/>}/>
          <Route path="/:sessionCode" element={<ClassSessionPage isSignedIn={isSignedIn} />}/>
          <Route exact path="/logout" element={<LogoutPage />} />
          <Route exact path="/" element={<Home isSignedIn={isSignedIn} />} />
          <Route path="/ContactUs" element={<ContactUs />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
