import { useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";


const Logout = () => {
  useEffect(() => {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
      console.log("Signed out");
      //window.location.href = "/";


      fetch("/api/logout").then(res => {
        if (res.ok) {
          window.location.href = "/";
        }
        else {
          console.log("Error logging out");
        }
      });


    }).catch((error) => {
      // An error happened.
      console.log("Error signing out");
    });
  }, []);
  return <div>Logging out</div>;
};

export default Logout;
