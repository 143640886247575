/**
 * Show a question and answer
 *
 */
const ShowEachQuestionAnswer = props => {
  
  let chatbotResponse = props.answer;
  console.log("chatbotResponse: ", chatbotResponse)

  if (chatbotResponse){
    const paragraphs = String(chatbotResponse).split("\n\n");
    console.log("paragraphs: ", paragraphs)
    if (paragraphs.length > 1){
      let scoreSentence = paragraphs.pop();
      chatbotResponse = chatbotResponse.replace(scoreSentence, "")
    }
  }
  
  

  return (
    <>
      <div className = "box has-text-left" style={{ marginRight: "5%" }}>
        User: {props.question}
      </div>
      {!chatbotResponse &&
        <div className = "box has-text-left" style={{ marginLeft: "5%" }}>
          ADIBILIS: Generating answer ...
        </div>
      }
      {chatbotResponse &&
        <div className = "box has-text-left" style={{ marginLeft: "5%", whiteSpace: "pre-wrap" }}>
          ADIBILIS: {chatbotResponse}
        </div>
      }
      <br/>
    </>
  );
};

export default ShowEachQuestionAnswer;
