import React from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../config/firebase-config";
import { collection, addDoc } from "firebase/firestore";

/**
 * ContactUs Page
 */
const ContactUsPage = () => {

  const handleSubmit = e => {
    e.preventDefault();
    const nameText = String(e.target.name.value);
    const emailText = String(e.target.email.value);
    const messageText = String(e.target.message.value);
    e.target.name.value = "";
    e.target.email.value = "";
    e.target.message.value = "";

    window.alert("Thank you for contacting us. You should hear from us shortly.")

    addDoc(collection(db, "contactUs"), {
    name: nameText,
    email: emailText,
    message: messageText,
  }).then((docRef) => {
    console.log("Contact Form saved with Firestore: ")
  })};

  return (
    <section className="hero">
      <div className="container hero-body has-text-centered">
        <br />
        <h1 className="title">Contact Us</h1>
        <form onSubmit={handleSubmit}>
          <br />
          <p className="subtitle">Send us a message</p>
          <label className="label has-text-left" htmlFor="name">
            Name
          </label>
          <input name="name" className="input" />
          <label className="label has-text-left" htmlFor="email">
            Email
          </label>
          <input name="email" className="input" />
          <label className="label has-text-left" htmlFor="message">
            Message
          </label>
          <textarea name="message" className="textarea" />
          <div>
            <br />
            <input type="submit" className="button is-black" value="Send" />
          </div>
        </form>
      </div>
    </section>
  )};
  export default ContactUsPage;