import React, { state } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import Popup from 'reactjs-popup';
import TCPopUp from "../components/TCPopUp";
import {db} from "../config/firebase-config";
import { collection, where, query, getDocs, updateDoc, doc } from "firebase/firestore";


/**
 * SignUp Page
 */
const SignUpPage = props => {
  const navigate = useNavigate();
  const auth = getAuth();
  //state = {seen: false};
  const terms_and_conditions = "The information provided on this website does not, and is not intended to, constitute legal advice; instead, all information, content, and materials available on this site are for general informational purposes only.Readers of this website should contact their attorney to obtain advice with respect to any particular legal matter.  No reader, user, or browser of this site should act or refrain from acting on the basis of information on this site without first seeking legal advice from counsel in the relevant jurisdiction.  Only your individual attorney can provide assurances that the information contained herein – and your interpretation of it – is applicable or appropriate to your particular situation.  Use of, and access to, this website or any of the links or resources contained within the site do not create an attorney-client relationship between the reader, user, or browser and website authors, and their respective employers."

  const checkboxHandler = () => {
    // Disable button if checkbox is not checked
    const checkbox = document.querySelector("#agree");
    const signUpButton = document.querySelector("#signUpButton");
    if (checkbox.checked) {
      signUpButton.disabled = false;

      //PopUp Terms and Conditions
      window.alert(terms_and_conditions)
      
      /*
      this.setState({
        seen: !this.state.seen
       });
      */
    } else {
      signUpButton.disabled = true;
    }
  }

  const signUpWithEmail = e => {
    e.preventDefault();
    // I should update this with "StyledFirebaseAuth" so I don't have to read the password manually
    const emailText = String(e.target.email.value);
    const passwordText = String(e.target.password.value);
    e.target.email.value = ""
    e.target.password.value = ""
    createUserWithEmailAndPassword(auth, emailText, passwordText)
      .then((userCredential) => {
        

        // Signed in 
        const user = userCredential.user;
        //console.log("user created: ", user.uid);
        const idtoken = user.getIdToken().then((idtoken) => {
          console.log("idtoken: ", idtoken);
          fetch("/api/login", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              idtoken: idtoken
            })
          })
            .then(res => {
              if (res.ok) { //same as using res.status === 200
                res.json().then(data => {
                  console.log("Got back ok response");
                  console.log("username: ", data.username);

                  // If there was a temp user, update owner name with the username
                  if (data.previousUsername) {
                    const q = query(collection(db, "sessions"), where("owner", "==", data.previousUsername));
                    getDocs(q).then((querySnapshot) => {
                      querySnapshot.forEach((docs) => {
                        // Update the owner name
                        updateDoc(doc(db, "sessions", docs.id), {
                          owner: user.uid
                        }).then(() => {
                          // Change the sign-in status and navigate to the user home page
                          props.onLogin(); 
                          navigate("/user-home");
                        })

                      });
                    });
                  } else {
                    // Change the sign-in status and navigate to the user home page
                    props.onLogin(); 
                    navigate("/user-home");
                  }
                })
              } else {
                console.log("Login went wrong");
              }
            })
        })
        
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("error: ", errorCode, errorMessage);
        window.alert(errorMessage);
      });
  }

  const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    if (window.confirm(terms_and_conditions)) {    

      signInWithPopup(auth, provider)
        .then((result) => {

          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const accesstoken = credential.accessToken;

          // The signed-in user info.
          const user = result.user;
          console.log("user log in: ", user.uid);

          // Send Firebase ID token to backend
          const idtoken = user.getIdToken().then((idtoken) => {
            console.log("idtoken: ", idtoken);

            fetch("/api/login", {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                idtoken: idtoken
              })
            })
              .then(res => {
                if (res.ok) { //same as using res.status === 200
                  res.json().then(data => {
                    console.log("Got back ok response");
                    console.log("username: ", data.username);

                    // If there was a temp user, update owner name with the username
                    if (data.previousUsername) {
                      const q = query(collection(db, "sessions"), where("owner", "==", data.previousUsername));
                      getDocs(q).then((querySnapshot) => {
                        querySnapshot.forEach((docs) => {
                          // Update the owner name
                          updateDoc(doc(db, "sessions", docs.id), {
                            owner: user.uid
                          }).then(() => {
                            // Change the sign-in status and navigate to the user home page
                            props.onLogin(); 
                            navigate("/user-home");
                          })

                        });
                      });
                    } else {
                      // Change the sign-in status and navigate to the user home page
                      props.onLogin(); 
                      navigate("/user-home");
                    }
                  })
                } else {
                  console.log("Login went wrong");
                }
              })
          })
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log("error: ", errorCode, errorMessage);
          // The email of the user's account used.
          //const email = error.customData.email;
          // The AuthCredential type that was used.
          //const credential = GoogleAuthProvider.credentialFromError(error);
        });
    }
  }


  return (
    <section className="hero">
      <div className="container hero-body has-text-centered">
        <br></br>
        <h1 className="title">Sign up to Adibilis </h1>
        <form onSubmit={signUpWithEmail}>
          <br></br><p className="subtitle"> Sign up with Email</p>
          <label className="label has-text-left" htmlFor="username">
            Email
          </label>
          <input name = "email" className="input"/> 
          <label className="label has-text-left" htmlFor="password">
            Password
          </label>
          <input name = "password" className="input" type = "password"/> 
          <div>
            <input type="checkbox" id="agree" onChange={checkboxHandler} />
            <label htmlFor="agree"> I agree to <b>terms and conditions</b></label>
          </div>
          <div>
            <br></br>
            <input type = "submit" className="button is-black" id = "signUpButton"/>
          </div>
        </form>

        {/*this.state.seen ? <TCPopUp toggle={this.togglePop} /> : null*/}

        <br></br><br></br>
        <button className="button" onClick={loginWithGoogle}>Sign up with Google</button>
      </div>
    </section>
  );
};

export default SignUpPage;
