/**
 * Show metrics
 *
 */
const ShowOrganizationsList = props => {

  console.log("Organization List to render: ", props.list)

  // To be implemented: Make phone and url clickable
  // 1) Extract phones and urls from props.list
  // 2) Make them clickable in html:
  // const url = 'https://example.com'
  // <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
  //const phoneNumber = '+1234567890';
  // <a href={`tel:${phoneNumber}`} {phoneNumber}</a> 
 


  return (
    <div>
      {!props.spanishLanguage && (
      <>
      <p className="paragraph has-text-weight-bold py-3 is-size-4 has-text-centered">
        Some organizations that can help you!
      </p>
      <div className="box has-background-info-light flex">
        <div style={{ overflowY: "scroll", height: "150px", whiteSpace: "pre-wrap"}}>   
          {props.list} 
        </div>
      </div>
      </>
      )}
      {props.spanishLanguage && (
      <>
      <p className="paragraph has-text-weight-bold py-3 is-size-4 has-text-centered">
        Organizaciones que pueden ayudarte!
      </p>
      <div className="box has-background-info-light flex">
        <div style={{ overflowY: "scroll", height: "150px", whiteSpace: "pre-wrap"}}>   
          {props.list} 
        </div>
      </div>
      </>
      )}

    </div>
  );
};

export default ShowOrganizationsList;
