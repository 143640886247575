import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./config/firebase-config"
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import mixpanel from 'mixpanel-browser';



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// Mixpanel Project Token
mixpanel.init('1886cef355765a12341d524e109beac5', {debug: true, ignore_dnt: true,});